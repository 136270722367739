const generateTexts = (clientName, values = {}) => ({
  
  startOne: `${clientName}, <strong>${values.requestedProduct || "{запрашиваемый Товар}"}</strong> будет стоить <strong>${
    values.pricePerPack || "{стоимость за пачку}"
  }</strong> руб. за пачку, на Ваш объем это <strong>${
    values.totalCost || "{общая стоимость за материал}"
  }</strong> руб. 
  Сейчас этот объем есть на складе, готов забронировать его за Вами. 
  Оформляем заказ?`,

  startMany: (items) => {
    const itemDetails = items
      .map(
        (item) =>
          `<strong>${item.name || "{название товара}"}</strong> будет стоить <strong>${
            item.pricePerPack || "{стоимость за пачку}"
          }</strong> руб. за пачку.`
      )
      .join("\n");

      const totalCost = items.reduce((sum, item) => {
        const cleanedCost = (item.totalCost || "0").replace(/\s+/g, ""); // Убираем пробелы
        return sum + (parseInt(cleanedCost, 10) || 0);
      }, 0);
  

    return `${clientName}, вот информация по Вашим товарам:\n${itemDetails}\n
    На Ваш объем это <strong>${totalCost.toLocaleString("ru-RU") || "{общая стоимость за материал}"}</strong> руб.
    Сейчас все эти позиции есть на складе, готов забронировать их за Вами. 
    Оформляем заказ?`;
  },
  
  priceOne: `У вас есть цена дешевле?`,

  popularOne: `Тогда ${clientName}, есть отличный вариант сэкономить, при этом получить утеплитель с лучшими характеристиками. 
  Это <strong>${values.popularAlternative || "{название популярного аналога}"}</strong>, при Вашем объеме Вы экономите <strong>${
    values.savings || "{сумма экономии от первоначального материала}"
  }</strong> руб., 
  Бронируем???`,

  budgetOne: `${clientName}, я так понимаю, для вас важна цена. 
  Тогда предлагаю Вам рассмотреть <strong>${
    values.budgetAlternative || "{наименование самого бюджетного аналога}"
  }</strong>, с ним Вы экономите <strong>${
    values.savings || "{сумма экономии от первоначального материала}"
  }</strong> руб.
  Бронируем???`,

  additional: `Это не так он по Всем характеристикам лучше чем Роквул/Технониколь/Парок/Хотрок, теплопроводность у него такая же, 
  а главное что у него более длинные волокна, соответственно они распределяются равномерно по всей площади, 
  а значит не будет мостиков холода.
  (Дом сохранит тепло и Вы сэкономите на отоплении)`,
});

export default generateTexts;
